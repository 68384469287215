export default {
  pixelRatio: 1.0,

  width: 1024,
  height: 1024,

  innerWidth: 1024,
  innerHeight: 600,

  color1: 0xfe6860,
  color2: 0xfe8a71,
  color3: 0xf3c9bf,
  color4: 0xd9bbae,

  skipFrames: false,

  speed: 10,
  amp: 70,
};
