export default {
  pixelRatio: 1.0,

  width: 1024,
  height: 1024,

  innerWidth: 1024,
  innerHeight: 600,

  color1: 0x043d5d,
  color2: 0x032e46,
  color3: 0x0f595e,
  color4: 0x23b684,

  skipFrames: false,

  speed: 10,
  amp: 320,
};
