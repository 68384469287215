import { Gradient } from "./gradient.js";

export default class GradientModule {
  constructor({ canvasSelector, wrapElem, config }) {
    this.canvas = document.querySelector(canvasSelector);
    this.context = this.canvas.getContext("webgl");
    this.wrap = wrapElem || window;

    this.state = config;

    this.gradient = new Gradient();
    this.gradient.initGradient(canvasSelector);
    this.recursiveInitialization();

    window.addEventListener("resize", () => {
      this.resize();
    });
  }

  recursiveInitialization() {
    if (this.gradient.mesh) {
      this.stateToGradientState();
    } else {
      window.requestAnimationFrame(() => {
        this.recursiveInitialization();
      });
    }
  }

  stateToGradientState() {
    this.gradient.setSkipFrame(this.state.skipFrames);

    this.gradient.mesh.material.uniforms["u_baseColor"].value = this.normalizeColor(this.state.color1);
    this.gradient.mesh.material.uniforms["u_waveLayers"].value[0].value.color.value = this.normalizeColor(this.state.color2);
    this.gradient.mesh.material.uniforms["u_waveLayers"].value[1].value.color.value = this.normalizeColor(this.state.color3);
    this.gradient.mesh.material.uniforms["u_waveLayers"].value[2].value.color.value = this.normalizeColor(this.state.color4);
    this.gradient.mesh.material.uniforms["u_vertDeform"].value.noiseSpeed.value = this.state.speed;
    this.gradient.mesh.material.uniforms["u_vertDeform"].value.noiseAmp.value = this.state.amp;

    this.gradientWidthRatio = this.state.innerWidth / this.state.width;
    this.gradientHeightRatio = this.state.innerHeight / this.state.height;

    this.resize();
  }

  normalizeColor(hexCode) {
    return [((hexCode >> 16) & 255) / 255, ((hexCode >> 8) & 255) / 255, (255 & hexCode) / 255];
  }

  resize() {
    this.width = (this.wrap.offsetWidth || this.wrap.innerWidth) * this.state.pixelRatio;
    this.height = (this.wrap.offsetHeight || this.wrap.innerHeight) * this.state.pixelRatio;

    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.canvas.style.width = this.width + "px";
    this.canvas.style.height = this.height + "px";

    this.context.viewport(0, 0, this.width, this.height);
   
    this.gradient.width = this.width * this.gradientWidthRatio;
    this.gradient.height = this.height * this.gradientHeightRatio;
    this.gradient.resize();
  }

  pause() {
    this.gradient.pause();
  }

  play() {
    this.gradient.play();
  }
}