import GradientModule from "./module";
import firstConfig from "./config/first";
import secondConfig from "./config/second";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function initFirstGradient() {
  const firstWrap = document.querySelector("#wrap-1");

  const firstGradient = new GradientModule({
    canvasSelector: "#gradient-1",
    wrapElem: firstWrap,
    config: firstConfig,
  });

  ScrollTrigger.create({
    trigger: firstWrap,
    start: "top bottom",
    end: "bottom top",
    onEnter: () => {
      firstGradient.play();
    },
    onEnterBack: () => {
      firstGradient.play();
    },
    onLeave: () => {
      firstGradient.pause();
    },
    onLeaveBack: () => {
      firstGradient.pause();
    },
  });
}

function initSecondGradient() {
  const secondWrap = document.querySelector("#wrap-2");
  const canvas = document.querySelector("#gradient-2");

  const secondGradient = new GradientModule({
    canvasSelector: "#gradient-2",
    wrapElem: secondWrap,
    config: secondConfig,
  });
  secondGradient.pause();

  gsap.set(canvas, { opacity: 0 });
  ScrollTrigger.create({
    trigger: secondWrap,
    start: "top 50%",
    end: "bottom 50%",
    onEnter: () => {
      secondGradient.play();
      fade(canvas, 1);
    },
    onEnterBack: () => {
      secondGradient.play();
      fade(canvas, 1);
    },
    onLeave: () => {
      secondGradient.pause();
      fade(canvas, 0);
    },
    onLeaveBack: () => {
      secondGradient.pause();
      fade(canvas, 0);
    },
  });
}

function fade(elem, opacity) {
  gsap.to(elem, {
    opacity: opacity,
    ease: 'none',
    duration: 0.4,
  });
}

initFirstGradient();
initSecondGradient();